import {
    PrivilegedRouteMap,
    PrivilegedRouteObject,
} from '@thoughtspot/blink-context';
import _ from 'lodash';
import React from 'react';
import { lazyWithPreload as lazy } from 'react-lazy-with-preload';
import { Navigate, useParams } from 'react-router-dom';
import { BlinkV2MetricsContextStates } from '/@services/system/blink-v2-metrics-context-states';
import { LeftPanelItem } from '/@utils/common-enum';
import { PrivilegeList } from './privilege-list';

export const EMBED_ROUTE = '/embed';

const AdhocAnswer = lazy(() => import('../pages/search-pages/adhoc-answer'));
const SavedAnswer = lazy(() => import('../pages/search-pages/saved-answer'));
const EurekaPage = lazy(() => import('../pages/eureka/eureka-page'));
const CreateAiAnswerPage = lazy(() =>
    import('../pages/create-ai-answer/create-ai-answer-page'),
);

const AiAnswer = lazy(() => import('../pages/ai-answer/ai-answer-page'));

const EditACopyPage = lazy(() => import('../pages/search-pages/edit-a-copy'));

const AutoAnswerPage = lazy(() =>
    import('../pages/search-pages/auto-answer-page'),
);

const EmbedSamlComplete = lazy(() =>
    import('../pages/embed-saml-complete/embed-saml-complete'),
);

const PrintView = lazy(() => import('../components/print/print-view'));

const Liveboards = lazy(() =>
    import('../components/home/liveboards/liveboards.container'),
);

const Homepage = lazy(() => import('../pages/homepage/home-page'));

const Answers = lazy(() =>
    import('../components/home/answers/answers.container'),
);

const PinboardPage = lazy(() =>
    import('../pages/pinboard-pages/pinboard-page'),
);

const PinboardSchedulePage = lazy(() =>
    import('../pages/pinboard-pages/pinboard-schedule'),
);

const PinboardEmbedPage = lazy(() =>
    import('../pages/pinboard-pages/pinboard-embed'),
);

const VizEmbedPage = lazy(() => import('../pages/pinboard-pages/viz-embed'));

const SearchBarEmbed = lazy(() =>
    import('../pages/search-pages/search-bar-embed'),
);

const SearchAssistPage = lazy(() =>
    import('../pages/search-pages/search-assist'),
);

const EmbedPage = lazy(() => import('../pages/embed/embed-page'));

const RequestAccessPage = lazy(() =>
    import('../pages/request-access/request-access-object-no-access-page'),
);

const SpotIQListPage = lazy(() =>
    import('../components/home/spotiq-analysis/spotiq-analysis.container'),
);

const SpotIQInsightPage = lazy(() =>
    import('../pages/spotiq/analysis-result-page'),
);

const ConvAssistPage = lazy(() =>
    import('../pages/conv-assist/conv-assist-page'),
);

const CaptainPage = lazy(() => import('../pages/captain/captain-page'));
const RedirectToSchedules = () => {
    const { pinboardId } = useParams();
    return <Navigate to={`/schedules/${pinboardId}`} replace />;
};

const insightsRoutes: PrivilegedRouteMap = {
    Answer: {
        path: '/insights?/answer',
        element: <AdhocAnswer />,
        pageContext: BlinkV2MetricsContextStates.ANSWER_PAGE,
        title: 'EmbedDev.Playground.FeatureType.queryBuilder',
    },
    AiAnswer: {
        path: '/insights?/ai-answer/:eurekaAnswerSessionId',
        element: <AiAnswer />,
        pageContext: BlinkV2MetricsContextStates.AI_ANSWER,
    },
    AutoAnswer: {
        path: '/answer/create/auto/:dataSourceId',
        element: <AutoAnswerPage />,
        pageContext: BlinkV2MetricsContextStates.AUTO_ANSWER,
    },
    EditACopy: {
        path: '/insights?/answer/edit/:editACopySessionKey',
        element: <EditACopyPage />,
        pageContext: BlinkV2MetricsContextStates.EDIT_A_COPY,
    },
    EurekaPage: {
        path: '/insights?/eureka',
        element: <EurekaPage />,
        pageContext: BlinkV2MetricsContextStates.EUREKA,
    },
    CreateAiAnswerPage: {
        path: '/insights?/create-ai-answer',
        element: <CreateAiAnswerPage />,
        pageContext: BlinkV2MetricsContextStates.CREATE_AI_ANSWER,
    },
    Default: {
        element: <Homepage id={LeftPanelItem.HOME} />,
        enableSearch: true,
        pageContext: BlinkV2MetricsContextStates.HOME_PAGE,
    },
    Home: {
        path: '*',
        element: <Homepage id={LeftPanelItem.HOME} />,
        enableSearch: true,
        pageContext: BlinkV2MetricsContextStates.HOME_PAGE,
    },
    StratusHome: {
        path: '/home',
        element: <Homepage id={LeftPanelItem.HOME} />,
        enableSearch: true,
        pageContext: BlinkV2MetricsContextStates.HOME_PAGE,
    },
    Pinboard: {
        path: '/insights?/pinboard/:pinboardId/:vizId?',
        element: <PinboardPage />,
        pageContext: BlinkV2MetricsContextStates.PINBOARD_PAGE,
    },
    HomeLiveboards: {
        id: 'liveboards',
        path: '/insights?/home?/liveboards',
        element: <Homepage id={LeftPanelItem.LIVEBOARDS} />,
        pageContext: BlinkV2MetricsContextStates.PINBOARDS_LIST,
        title: 'homeleftpanel.liveboards',
    },
    Pinboards: {
        path: '/insights?/pinboards',
        element: <Liveboards />,
        pageContext: BlinkV2MetricsContextStates.PINBOARDS_LIST,
        title: 'homeleftpanel.liveboards',
    },
    Liveboards: {
        path: '/insights?/liveboards',
        element: <Liveboards />,
        pageContext: BlinkV2MetricsContextStates.PINBOARDS_LIST,
        title: 'homeleftpanel.liveboards',
    },
    SpotIQAnalySis: {
        id: LeftPanelItem.SPOTIQ_ANALYSIS,
        path: '/insights?/home?/spotiq-analysis',
        element: <Homepage id={LeftPanelItem.SPOTIQ_ANALYSIS} />,
        privileges: [PrivilegeList.CanAccessA3],
        pageContext: BlinkV2MetricsContextStates.SPOTIQ_ANALYSIS,
        title: 'a3.VIEW_SPOTIQ_ANALYSIS',
    },
    SpotIQAnalysis: {
        path: '/insights/analyse',
        element: <Homepage id={LeftPanelItem.SPOTIQ_ANALYSIS} />,
        privileges: [PrivilegeList.CanAccessA3],
        pageContext: BlinkV2MetricsContextStates.SPOTIQ_ANALYSIS,
        title: 'a3.VIEW_SPOTIQ_ANALYSIS',
    },
    SpotIQAnalysisV1Path: {
        path: '/insights/results',
        element: <Homepage id={LeftPanelItem.SPOTIQ_ANALYSIS} />,
        privileges: [PrivilegeList.CanAccessA3],
        pageContext: BlinkV2MetricsContextStates.SPOTIQ_ANALYSIS,
        title: 'a3.VIEW_SPOTIQ_ANALYSIS',
    },
    MonitorAlerts: {
        id: LeftPanelItem.MONITOR_ALERTS,
        path: '/insights?/home?/monitor-alerts',
        element: <Homepage id={LeftPanelItem.MONITOR_ALERTS} />,
        privileges: [PrivilegeList.CanAccessMonitor],
        pageContext: BlinkV2MetricsContextStates.MONITOR_RULE_LIST,
        title: 'homeleftpanel.monitorsubscription',
    },
    Monitor: {
        path: '/monitor',
        element: <Homepage id={LeftPanelItem.MONITOR_ALERTS} />,
        privileges: [PrivilegeList.CanAccessMonitor],
        pageContext: BlinkV2MetricsContextStates.MONITOR_RULE_LIST,
        title: 'homeleftpanel.monitorsubscription',
    },
    Answers: {
        path: '/insights?/answers',
        element: <Answers />,
        pageContext: BlinkV2MetricsContextStates.ANSWERS_LIST,
        title: 'homeleftpanel.answers',
    },
    HomeAnswers: {
        id: LeftPanelItem.ANSWERS,
        path: '/insights?/home/answers',
        element: <Homepage id={LeftPanelItem.ANSWERS} />,
        pageContext: BlinkV2MetricsContextStates.ANSWERS_LIST,
        title: 'homeleftpanel.answers',
    },
    HomeFavourites: {
        id: LeftPanelItem.FAVOURITES,
        path: '/insights?/home?/favourites',
        element: <Homepage id={LeftPanelItem.FAVOURITES} />,
        pageContext: BlinkV2MetricsContextStates.FAVORITE,
        title: 'homeleftpanel.home.favourites',
    },
    CreatedByMe: {
        id: LeftPanelItem.CREATED_BY_ME,
        path: '/insights?/home?/created-by-me',
        element: <Homepage id={LeftPanelItem.CREATED_BY_ME} />,
        pageContext: BlinkV2MetricsContextStates.CREATED_BY_ME,
        title: 'homeleftpanel.createdbyme',
    },
    PinboardEmbed: {
        path: '/insights?/viz/:pinboardId',
        element: <PinboardEmbedPage />,
        pageContext: BlinkV2MetricsContextStates.PINBOARD_CARD_ANSWER,
    },
    PinboardSchedule: {
        path: '/insights?/schedules/:pinboardId',
        element: <PinboardSchedulePage />,
        pageContext: BlinkV2MetricsContextStates.PINBOARD_SCHEDULE,
    },
    // adding a schedule route to handle the request from blink-v1 components.
    PinboardScheduleForBlink: {
        path: '/insights?/schedule/:pinboardId/:scheduleId',
        element: <RedirectToSchedules />,
        pageContext: BlinkV2MetricsContextStates.PINBOARD_SCHEDULE,
    },
    PrintView: {
        path: '/insights?/print',
        element: <PrintView />,
        disableTopNav: true,
        pageContext: BlinkV2MetricsContextStates.PRINT_PAGE,
    },
    RequestAccess: {
        path: '/requestaccess/:objectType/:objectId',
        element: <RequestAccessPage />,
        pageContext: BlinkV2MetricsContextStates.REQUEST_ACCESS,
    },
    SAMLComplete: {
        path: '/saml-complete',
        element: <EmbedSamlComplete />,
        pageContext: BlinkV2MetricsContextStates.SAML_COMPLETE,
    },
    SavedAnswer: {
        path: '/insights?/saved-answer/:answerId',
        element: <SavedAnswer key={BlinkV2MetricsContextStates.ANSWER} />,
        pageContext: BlinkV2MetricsContextStates.SAVED_ANSWER,
    },
    SearchAssist: {
        path: '/search-assist',
        element: <SearchAssistPage />,
        disableTopNav: true,
        pageContext: BlinkV2MetricsContextStates.SEARCH_ASSIST,
    },
    SearchBarEmbed: {
        path: '/search-bar-embed',
        element: <SearchBarEmbed />,
        pageContext: BlinkV2MetricsContextStates.SEARCH_BAR_EMBED,
    },
    SpotIQList: {
        path: '/insights',
        element: <SpotIQListPage />,
        pageContext: BlinkV2MetricsContextStates.SPOTIQ_ANALYSIS,
    },
    SpotIQ: {
        path: '/insights?/insight/:analysisResultId',
        element: <SpotIQInsightPage />,
        pageContext: BlinkV2MetricsContextStates.SPOTIQ_ANALYSIS,
    },
    Tab: {
        path: '/insights?/pinboard/:pinboardId/tab/:tabId/:vizId?',
        element: <PinboardPage />,
        pageContext: BlinkV2MetricsContextStates.PINBOARD_PAGE,
    },
    TabEmbed: {
        path: '/insights?/viz/:pinboardId/tab/:tabId/:vizId?',
        element: <PinboardEmbedPage />,
        pageContext: BlinkV2MetricsContextStates.PINBOARD_PAGE,
        disableTopNav: true,
    },
    View: {
        path: '/insights?/view/:answerId',
        element: (
            <SavedAnswer isSavedView key={BlinkV2MetricsContextStates.VIEW} />
        ),
        pageContext: BlinkV2MetricsContextStates.VIEW,
    },
    VizEmbed: {
        path: '/insights?/viz/:pinboardId/:vizId',
        element: <VizEmbedPage />,
        pageContext: BlinkV2MetricsContextStates.PINBOARD_CARD_ANSWER,
        disableTopNav: true,
    },
    ConvAssist: {
        path: '/insights?/conv-assist',
        element: <ConvAssistPage />,
        pageContext: BlinkV2MetricsContextStates.CONV_ASSIST,
    },
    CaptainPage: {
        path: '/insights?/captain/*',
        element: <CaptainPage />,
        pageContext: BlinkV2MetricsContextStates.CAPTAIN,
    },
};

/**
 * Enhance the base routes object with the embeddable routes
 * @param sourceRoutes The base routes of the app
 * @param embedRoutes The routes that can be embedded
 */
function addEmbedRoutes(
    sourceRoutes: PrivilegedRouteMap,
    embedRoutes: PrivilegedRouteObject[],
) {
    const routes = { ...sourceRoutes };
    embedRoutes.forEach(embedRoute => {
        const routeKey = _.findKey(
            sourceRoutes,
            route => route.path === embedRoute.path,
        );
        routes[`Embed_${routeKey}`] = {
            path: `${EMBED_ROUTE}${embedRoute.path}`,
            element: (
                <EmbedPage
                    component={embedRoute.element as React.ReactElement}
                />
            ),
            disableTopNav: true,
            pageContext: embedRoute.pageContext,
        };
    });

    return routes;
}

// The whitelist of embeddable routes
const embedRouteList = [
    insightsRoutes.SavedAnswer,
    insightsRoutes.Answer,
    insightsRoutes.EditACopy,
    insightsRoutes.SAMLComplete,
    insightsRoutes.PinboardEmbed,
    insightsRoutes.VizEmbed,
    insightsRoutes.TabEmbed,
    insightsRoutes.SearchBarEmbed,
    insightsRoutes.EurekaPage,
];

export const INSIGHTS_ROUTES = addEmbedRoutes(insightsRoutes, embedRouteList);

export const INSIGHTS_ROUTE_IDS: any = Object.keys(INSIGHTS_ROUTES).reduce(
    (acc, key) => {
        acc[key] = key;
        return acc;
    },
    {},
);
