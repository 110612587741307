import { ApolloProvider } from '@apollo/client';
import { GlobalAlertContextProvider } from '@thoughtspot/global-alert';
import { LoadingIndicator } from '@thoughtspot/radiant-react/widgets/loading-indicator';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import {
    AppContextOptions,
    AppContextProvider,
} from '/@contexts/global-app-context';
import { ClientStateContextProvider } from '/@contexts/global-client-state';
import { isEmbeddedApp } from '/@utils/embed-base.util';
import { ErrorBoundary } from '/@utils/error-boundary/error-boundary';
import { BlinkErrorBoundary } from '/@utils/error-handling/blink-error-boundary';
import { client } from './apollo-client';
import { EmbedContainer } from './components/embed/embed.container';
import styles from './routing/authenticated-app-view.module.scss';
/*
 * If you are creating a new fullscreen dialog UI and you want red bar errors to appear in a custom
 * element inside your dialog, you can tag the dialog using this ID. Then you can instantiate a new
 * GlobalAlertContextProvider at the top-level of your dialog component, and pass the same ID.
 */
export const FULLSCREEN_DIALOG_GLOBAL_ALERT_CONTAINER_ID =
    'fullscreenDialogGlobalAlertContainerId';

/**
 * Return the component wrapped with some context providers
 * but without Apollo and ClientState Provider.
 *
 * For answer-v2 in pinboard-v1, we render v2 component at each tile.
 * So we want to avoid having multiple instances of apollo provider on the page.
 */
export function withProvidersNoApolloAndClientState(
    Component: React.ReactElement,
    options: AppContextOptions,
    isEmbedded = false,
    customBannerAlertContainerId = '',
): React.ReactElement {
    const wrappedComponent = (
        <GlobalAlertContextProvider
            customBannerAlertContainerId={customBannerAlertContainerId}
        >
            {Component}
        </GlobalAlertContextProvider>
    );
    return (
        <AppContextProvider {...options}>
            <BlinkErrorBoundary handledErrors={['*']}>
                <Suspense
                    fallback={
                        <LoadingIndicator.Global
                            className={styles.loadingIndicator}
                        >
                            <></>
                        </LoadingIndicator.Global>
                    }
                >
                    {isEmbedded ? (
                        <EmbedContainer
                            doNotWrapInContainer
                            component={wrappedComponent}
                        />
                    ) : (
                        wrappedComponent
                    )}
                </Suspense>
            </BlinkErrorBoundary>
        </AppContextProvider>
    );
}

export function withoutErrorBoundary(
    Component: React.ReactElement,
    options: AppContextOptions,
    isEmbedded = false,
    customBannerAlertContainerId = '',
): React.ReactElement {
    return (
        <ApolloProvider client={client}>
            <ClientStateContextProvider>
                {withProvidersNoApolloAndClientState(
                    Component,
                    options,
                    isEmbedded,
                    customBannerAlertContainerId,
                )}
            </ClientStateContextProvider>
        </ApolloProvider>
    );
}
/**
 * Return the component wrapped with all necessary context providers
 */
export async function withProviders(
    Component: React.ReactElement,
    options: AppContextOptions,
    _isEmbedded = false,
    customBannerAlertContainerId = '',
): Promise<React.ReactElement> {
    // Not using isEmbedded from embedContext bcoz embed context is not initialized at this point.
    const isEmbedded = _isEmbedded || isEmbeddedApp();
    return (
        <ErrorBoundary>
            {withoutErrorBoundary(
                Component,
                options,
                isEmbedded,
                customBannerAlertContainerId,
            )}
        </ErrorBoundary>
    );
}

export async function renderApp(
    element: Element,
    App: React.FC,
    options: AppContextOptions,
) {
    const app = React.createElement(App);
    ReactDOM.render(await withProviders(app, options), element);
}
