import {
    BLINK_FLAGS,
    containsV1inPath,
    envFlags,
    FLAGS,
    getFlagsFromSimpleJSON,
    getQueryParamsAndQueryString,
    IEnvFlags,
    initEnvFlags,
} from '@thoughtspot/env-flags';

export function initFlags() {
    // Get blink.env and convert to flags
    const blinkEnvFlags = getFlagsFromSimpleJSON((window as any).blink?.env);

    // Merge all flags together
    const mergedFlags = {
        ...BLINK_FLAGS,
        ...blinkEnvFlags,
    };

    // Call Init from the env-flags package with blink specific flags.
    // This will set the right defaults as well.
    initEnvFlags(mergedFlags);

    const queryParams = getQueryParamsAndQueryString().queryParams;
    const queryFlags = getFlagsFromSimpleJSON(queryParams, true);

    // Call init with queryFlags, init is called again here, since we have to override
    // the existing flags, so all Flags should have already been init'ed.
    initEnvFlags(queryFlags);
}

export { containsV1inPath, FLAGS, getQueryParamsAndQueryString };

export const flags: IEnvFlags = {
    getValue: envFlags.getValue,
    setValue: envFlags.setValue,
    resetValue: envFlags.resetValue,
};
