import { blinkConstants } from '@thoughtspot/blink-constants';
import { cloneDeep } from 'lodash';
import { useEffect } from 'react';
// import { EventStatus, EventType } from '../embed.util';
import { EventStatus } from '../embed-base.util';

const EventType = blinkConstants.embedEvents;

// TODO: use enum from Embed SDK
type MessageType = string;

const eventHandlerMap = new Map<MessageType, Set<any>>();
const onMessage = (event: any) => {
    const type = event.data?.type;
    const handlers = eventHandlerMap.get(type);
    if (handlers) {
        handlers.forEach(async callback => {
            const data = await callback(event.data?.data);
            if (data && event.ports) {
                event.ports[0].postMessage(cloneDeep(data));
            }
            return data;
        });
    }
};

export const init = () => {
    window.addEventListener('message', onMessage);
};

export const destroy = () => {
    window.removeEventListener('message', onMessage);
};

const unsubscribe = (type: MessageType, callback: any) => {
    const handlersForType = eventHandlerMap.get(type);
    if (handlersForType) {
        handlersForType.delete(callback);
    }
};

export const subscribe = (
    type: MessageType,
    callback: any,
    forceDelete = false,
) => {
    // To clear all the listners attached to the type.
    if (forceDelete && eventHandlerMap.get(type)) {
        eventHandlerMap.delete(type);
    }
    if (!eventHandlerMap.get(type)) {
        eventHandlerMap.set(type, new Set<any>());
    }
    eventHandlerMap.get(type).add(callback);

    return () => unsubscribe(type, callback);
};

export const createEventSubscriber = () => (
    type: MessageType,
    callback: any,
) => {
    useEffect(() => {
        subscribe(type, callback);

        // clean up
        return () => unsubscribe(type, callback);
    }, []);
};

const computeHash = (data: any) => {
    if (typeof data === 'object' || Array.isArray(data)) {
        return JSON.stringify(data);
    }

    return `${data}`;
};

let parentOrigin: string = null;

export const setParentOrigin = (origin: string, _protocol?: string) => {
    // We try to ensure that only the embedding (parent) application
    // receives the message
    const fallBack =
        window.location !== window.parent.location
            ? document.referrer
            : document.location.href;

    const fallBackProtocol = document.referrer.split('://')[0];

    let protocol = _protocol?.toLowerCase() || fallBackProtocol;

    if (protocol !== 'http' && protocol !== 'https') {
        protocol = 'https';
    }

    // In case of localhost or 127.0.0.1, sdk sets hostAppUrl as local-host
    // in that case return null
    // https://github.com/thoughtspot/visual-embed-sdk/blob/main/src/embed/ts-embed.ts#L398
    const sdkOrigin =
        origin && origin !== 'local-host' ? `${protocol}://${origin}` : null;
    parentOrigin = sdkOrigin || fallBack || '*';
};

export const getParentOrigin = () => {
    return parentOrigin;
};
/**
 * Dispatch an event to the parent app
 * @param nonMemoizedEventTypes Event types which should not be memoized, i.e.,
 * for which the same payload is allowed to be triggered again and again
 * @returns A function to dispatch the event, by default triggering the same
 * payload for a particular event type twice in a row is a no-op
 */
export const dispatchEvent = (nonMemoizedEventTypes?: Set<string>) => {
    // Holds the hash of the payload this particular message type
    // was last triggered with
    const memo = new Map<MessageType, string>();
    return (type: MessageType, payload: any, status = EventStatus.End) => {
        if (window === window.parent) {
            // In non-embed case where the url is opened directly, window.parent is same as window.
            return Promise.resolve();
        }
        return new Promise((resolve, reject) => {
            const channel = new window.MessageChannel();
            channel.port1.onmessage = ({ data }) => {
                channel.port1.close();
                if (data.error) {
                    reject(data.error);
                } else {
                    resolve(data);
                }
            };

            let dispatchRepeat = nonMemoizedEventTypes?.has(type);
            if (!dispatchRepeat) {
                // Send message only if payload has changed for a particular type
                const hash = computeHash(payload);
                const prevHash = memo.get(type);

                dispatchRepeat = !prevHash || hash !== prevHash;
                if (dispatchRepeat) {
                    memo.set(type, hash);
                }
            }
            if (dispatchRepeat) {
                window.parent.postMessage(
                    { type, data: cloneDeep(payload), status },
                    parentOrigin,
                    [channel.port2],
                );
            }
        });
    };
};

const NON_MEMOIZED_EVENTS = [EventType.AuthExpire];
export const dispatchNonMemoEvent = dispatchEvent(new Set(NON_MEMOIZED_EVENTS));

export const EventBridgeService = {
    createEventSubscriber,
    dispatchEvent,
    dispatchNonMemoEvent,
    init,
    destroy,
};

export default EventBridgeService;
