import { ApolloClient } from '@apollo/client';
import { create } from '@thoughtspot/logger';
import _ from 'lodash';
import { Subject } from 'rxjs';
import {
    Column,
    PhraseType,
    RecognizedTokenType,
    SagePhrase,
    SageRequestFlag,
    SageToken,
    SageTokenInput,
} from '/@services/generated/graphql-types';
import { MetricsService } from '/@services/metrics-service/metrics-service';
import {
    BlinkCompletion,
    BlinkPhrase,
    BlinkToken,
    SageMixpanelInfo,
} from '/@services/search/search-client.util';
import { SearchAssistClient } from '/@services/search-assist-v2/search-assist-client';

/*
 * Several components in the sage stack pass along props to components lower in the stack. To avoid
 * redefining the same prop interface for these common props, we instead define them here and each
 * sage component extends this interface with its own set of props.
 */
export interface SageComponentBaseProps {
    /*
     * Services
     */
    // Service for metrics tracking
    metricsService: MetricsService;

    /*
     * Static visual settings
     */
    // Whether the sage bar should be focused upon first render
    isFocusedOnInit: boolean;
    // Text to display while the sage bar is empty and not in focus
    placeholderText: string;
    // Tooltip to display on the sage bar body (ie. empty space where there are no tokens)
    bodyTooltip?: string;

    /*
     * Sage request related
     */
    // Whether we got a response to the very first sage request
    isInitialRequestComplete: boolean;
    // Current phrase data to display in the sage bar
    phrases: BlinkPhrase[];
    // Whether the phrases in the previous prop came from an external source. We use this to
    // determine whether to gate the data on a text match with the current token-bar contents
    // before being applied to the token-bar. If the phrase data came from an external source, we
    // accept it without any text matching requirement. If not, that means the phrase data came
    // from a regular sage request, so we make sure the phrase text matches the current text in the
    // token-bar. If no match, we ignore the new phrase data, since it means the user has made an
    // edit that is more recent than this phrase data.
    isPhraseDataUpdatedExternally: boolean;
    // Current completions data to display in the dropdown
    completions: BlinkCompletion[];
    // Whether more completions are available for the current query and caret position
    hasMoreCompletions: boolean;
    // Whether it is impossible to show any completions for the current state of the sage query at
    // the current caret position. This can be used to determine whether to show an empty state in
    // the completions dropdown, or to hide the dropdown altogether.
    completionsNotPossible: boolean;
    // Whether there was an error in the last sage response
    hasError: boolean;

    /*
     * Imperative handling event emitters
     */
    // Emitting on this subject will cause the sage bar to move the caret to the end of the token
    // at the specified index (it will be at the end of the token itself, not moved out into the
    // subsequent separator). Will focus the sage bar if it is currently blurred. Emitting -1 will
    // place the caret at the end of the query text (in the separator).
    placeCaretAfterTokenAtIdxSubject?: Subject<number>;
    // Emitting on this subject will cause the sage bar to blur and make a sage request with its
    // current contents (will update answer if required as a result)
    blurAndSubmitSearchSubject?: Subject<void>;

    /*
     * Callbacks
     */
    // Callback for when the focus state of the sage bar changes
    onFocusChanged: (isVisible: boolean) => void;
    // Callback for when we want to fetch more completions
    // TODO(Rifdhan) we can probably combine this with onTextOrCaretPositionChanged
    getMoreCompletions: (
        updatedTokens: SageTokenInput[],
        caretTokenIdx: number,
        charOffsetInCaretToken: number,
        sageRequestFlags?: SageRequestFlag[],
    ) => void;
    // Callback when the user submits the search (eg pressing enter, clicking the search button)
    onSearchSubmitted: () => void;

    /*
     * Search Assist related
     */
    // Search Assist client instance
    searchAssistClient: SearchAssistClient;
    // Latest Search Assist response data
    searchAssistResponse: any; // TODO(Utsav) proper types
    // Data about the sage query required for metrics
    mixpanelSageData: SageMixpanelInfo; // TODO(Rifdhan) do we need this?

    /*
     * Others
     */
    // Data about the answer required for metrics
    getAnswerMixpanelProperties: () => any; // TODO(Utsav) add proper typing
    // Callback to dispatch an embed-related event
    dispatchEmbedEvent: (event: string, data: any) => void;
}

const logger = create('Sage-Util');

export function getTextForPhrases(phrases: BlinkPhrase[]): string {
    return phrases
        .map((phrase: BlinkPhrase) => {
            return phrase.tokens
                .map((token: BlinkToken) => {
                    return token.text + (token.hasSpaceAfter ? ' ' : '');
                })
                .join('');
        })
        .join('');
}

export function getTextForTokens(
    tokens: (BlinkToken | SageToken | SageTokenInput)[],
): string {
    return tokens
        .map((token: BlinkToken | SageToken | SageTokenInput) => {
            // If the token specifies trailingWhitespace, use that. If not, fall back to the older
            // hasSpaceAfter field instead. We need a bit of type checking to make this work.
            let trailingWhitespace: string;
            if (
                !_.isNil(
                    (token as SageToken | SageTokenInput).trailingWhitespace,
                )
            ) {
                trailingWhitespace = (token as SageToken | SageTokenInput)
                    .trailingWhitespace;
            } else {
                trailingWhitespace = token.hasSpaceAfter ? ' ' : '';
            }

            return token.text + trailingWhitespace;
        })
        .join('');
}

/*
 * Return true if a column has synonym
 */
export function isSynonym(
    token: BlinkToken | SageToken | SageTokenInput,
): boolean {
    return !_.isUndefined(
        (token.recognizedTokenJson as { [key: string]: any }).synonymSource,
    );
}

export function isQueryEmpty(phrases: BlinkPhrase[] | SagePhrase[]): boolean {
    if (!phrases) {
        return true;
    }

    return !phrases.some((phrase: BlinkPhrase | SagePhrase) => {
        return phrase.tokens.some((token: BlinkToken | SageToken) => {
            return token.text.length > 0;
        });
    });
}

export const areSagePhrasesPresent = (phrases: SagePhrase[] = []): boolean => {
    return (
        Array.isArray(phrases) &&
        Array.isArray(phrases[0]?.tokens) &&
        phrases[0].tokens.length > 0
    );
};

export const convertSagePhrasesToTextTokens = (
    phrases: SagePhrase[] = [],
): string[] => {
    return (
        phrases
            .filter(phrase => phrase.type !== PhraseType.UndefinedPhrase)
            .map(phrase => phrase.tokens.map(token => token.text).join(' '))
            .filter(tokenStr => tokenStr !== '') || []
    );
};

export function countTokensInPhrases(phrases: BlinkPhrase[]): number {
    return phrases.reduce((count: number, phrase: BlinkPhrase) => {
        return count + phrase.tokens.length;
    }, 0);
}

/*
 * Given an absolute token index among the flat set of all tokens, convert it into a (phrase index,
 * token index in that phrase) pair. This is useful when converting between a model where tokens
 * are nested within phrase objects, and a model where we have a flat list of all tokens.
 */
export function getPhraseAndTokenIdxForAbsoluteTokenIdx(
    phrases: SagePhrase[] | BlinkPhrase[],
    targetAbsoluteTokenIdx: number,
) {
    let absoluteTokenIdx = 0;
    for (let phraseIdx = 0; phraseIdx < phrases.length; phraseIdx++) {
        const currentPhrase = phrases[phraseIdx];
        for (
            let tokenIdx = 0;
            tokenIdx < currentPhrase.tokens.length;
            tokenIdx++
        ) {
            if (absoluteTokenIdx === targetAbsoluteTokenIdx) {
                return {
                    phraseIdx,
                    tokenIdx,
                };
            }

            absoluteTokenIdx++;
        }
    }

    logger.error(
        'Target token index is beyond the total number of tokens in all phrases',
        phrases,
        targetAbsoluteTokenIdx,
    );
    return {
        phraseIdx: 0,
        tokenIdx: 0,
    };
}

export const convertTokensToInputTokens = (
    tokens: SageToken[],
): SageTokenInput[] => {
    return tokens.map(
        (token: SageToken): SageTokenInput => ({
            text: token.text,
            hasSpaceAfter: token.hasSpaceAfter,
            trailingWhitespace: token.trailingWhitespace,
            recognizedTokenJson: token.recognizedTokenJson,
        }),
    );
};

/*
 * Mapping from the textual representation of select keywords and operators to a string describing
 * it for metrics purposes. We only need to include any keywords/operators which contain special
 * characters (not just a-z characters). See the usage site for more details.
 */
const KEYWORD_OR_OPERATOR_TEXT_TO_METRICS_STRING = {
    '=': 'equals',
    '!=': 'notEquals',
    '>': 'greaterThan',
    '>=': 'greaterThanEquals',
    '<': 'lessThan',
    '<=': 'lessThanEquals',
    '(': 'openBracket',
    ')': 'closeBracket',
};

/*
 * Constructs a string describing a token for metrics purposes. Includes information about the
 * token type and data type, as well as extra information about operators, keywords, and formula
 * functions if present.
 */
export const getMetricsStructureStringForToken = (token: SageToken): string => {
    let dataTypeString: string;
    if (
        token.type === RecognizedTokenType.Keyword ||
        token.type === RecognizedTokenType.Operator
    ) {
        // Keywords and operators don't have a data type, instead just use the text of the keyowrd
        // or operator, but camelCased so we remove any spaces. If it contains special characters,
        // we must handle it ourselves because the loadash function removes any special characters.
        dataTypeString =
            KEYWORD_OR_OPERATOR_TEXT_TO_METRICS_STRING[token.text] ??
            _.camelCase(token.text);
    } else if (
        token.type === RecognizedTokenType.FunctionName ||
        token.type === RecognizedTokenType.Delimiter
    ) {
        // Formula functions and delimiters (eg brackets) don't have a data type, instead use the
        // token text itself (should not contain spaces, so no post-processing should be needed)
        dataTypeString = _.camelCase(token.text);
    } else {
        dataTypeString = _.camelCase(token.dataType);
    }

    return `${_.camelCase(token.type)}/${dataTypeString}`;
};

/**
 * Filters a list of data for a source by the given search text
 * This supports multi word partial search, so for example, multi word columns
 * such as "Customer Address", can be searched via the query "Cus Ad"
 */
export const getFilteredResultsBySearchText = (
    options: { searchableName: string; data: any }[],
    searchText = '',
    wordPrefixMatchesOnly = false,
) => {
    // Detects space and special chars in a string, which are used to separate "words"
    const specialCharRegex = new RegExp(/[ ,.\-_]+/);

    return options
        .filter(item => {
            // split search and searchable text, "Cus Ad" -> ["cus", "ad"]
            const searchTextWords = searchText
                .toLowerCase()
                .trim()
                .split(specialCharRegex);
            const itemNameWords = item.searchableName
                .toLowerCase()
                .trim()
                .split(specialCharRegex);

            // if there are more search words than colName words, it's not a match
            if (searchTextWords.length > itemNameWords.length) {
                return false;
            }

            // loop through search words to see if it matches with any of the searchable words
            for (let i = 0; i < searchTextWords.length; i++) {
                const searchTextWord = searchTextWords[i];
                const searchedWordMatchIndex = itemNameWords.findIndex(
                    searchedName =>
                        wordPrefixMatchesOnly
                            ? searchedName.startsWith(searchTextWord)
                            : searchedName.includes(searchTextWord),
                );

                // if the searchable name doesn't match with any of the search text words
                // so for example "Revenue" neither has "Cus" nor "Ad", so it
                // will be filtered out here, and "Supplier Address" has "Ad" but
                // not "Cus", so that will also be filtered out
                if (searchedWordMatchIndex === -1) {
                    return false;
                }

                // itemNameWords got matched so we don't have to consider it for next searchTextWord
                // for example for the search "Cu", and the column "Customer Custkey",
                // Once we we find "Customer" to be matching with "Cu", we don't need to
                // match it agan with "Custkey" because oveall it's already a match.
                itemNameWords.splice(searchedWordMatchIndex, 1);
            }
            return true;
        })
        .map(item => item.data);
};

/**
 * Filters a list of data panel columns for a source by the given search text
 * This supports multi word partial search, so for example, multi word columns
 * such as "Customer Address", can be searched via the query "Cus Ad"
 */
export const getFilteredColumnsBySearchText = (
    columns: Column[],
    searchText: string,
    wordPrefixMatchesOnly = false,
) => {
    return getFilteredResultsBySearchText(
        columns.map(column => ({
            searchableName: column.name,
            data: column,
        })),
        searchText,
        wordPrefixMatchesOnly,
    );
};

export const clearSageSearchResponseApolloCache = (
    client: ApolloClient<object>,
    answerSessionId: string,
) => {
    client.cache.evict({
        id: client.cache.identify({
            id: {
                sessionId: answerSessionId,
            },
            __typename: 'SageSearchResponse',
        }),
    });
};

export const clearSageSearchSubject = new Subject();
