import { create } from '@thoughtspot/logger';
import { UserAction, userActionTypeEnum } from '@thoughtspot/metrics';
import { Microfrontend } from '@thoughtspot/microfrontends';
import React, { useEffect } from 'react';
import { useAppContext } from '/@contexts/global-app-context';
import {
    getMfeResourceUrls,
    getMicroFrontendComponent,
} from './microfrontends.util';

const initializedMicroFrontends: Map<Microfrontend, boolean> = new Map();
const logger = create('MFE');

/**
 * A copy of React's prop types check. The production build strips this part,
 * we are adding manually here to enable for validation in production too.
 * @param typeSpecs - PropTypes
 * @param values - Props
 * @param componentName - Component name
 * @param location - type
 */
function checkPropTypes(
    typeSpecs: any,
    values: any,
    componentName: string,
    location = 'prop',
) {
    // $FlowFixMe This is okay but Flow doesn't know it.
    const has = Function.call.bind(Object.prototype.hasOwnProperty);

    Object.keys(typeSpecs).forEach(typeSpecName => {
        if (has(typeSpecs, typeSpecName)) {
            let error$1: any; // Prop type validation may throw. In case they do, we don't want to
            // fail the render phase where it didn't fail before. So we log it.
            // After these have been cleaned up, we'll let them throw.

            try {
                // This is intentionally an invariant that gets caught. It's the same
                // behavior as without this statement except with a better message.
                if (typeof typeSpecs[typeSpecName] !== 'function') {
                    const err = Error(
                        `${componentName ||
                            'React class'}: ${location} type \`${typeSpecName}\` is invalid; ` +
                            `it must be a function, usually from the \`prop-types\` package, but received \`${typeof typeSpecs[
                                typeSpecName
                            ]}\`.` +
                            'This often happens because of typos such as `PropTypes.function` instead of `PropTypes.func`.',
                    );
                    err.name = 'Invariant Violation';
                    throw err;
                }

                error$1 = typeSpecs[typeSpecName](
                    values,
                    typeSpecName,
                    componentName,
                    location,
                    null,
                    'SECRET_DO_NOT_PASS_THIS_OR_YOU_WILL_BE_FIRED',
                );
            } catch (ex) {
                error$1 = ex;
            }

            if (error$1 && !(error$1 instanceof Error)) {
                logger.error(
                    `${componentName}: type specification of ${location} \`${typeSpecName}\` is invalid; the type checker ` +
                        `function must return \`null\` or an \`Error\` but returned a ${typeof error$1}. ` +
                        'You may have forgotten to pass an argument to the type checker ' +
                        'creator (arrayOf, instanceOf, objectOf, oneOf, oneOfType, and ' +
                        'shape all require an argument).',
                );
            }

            if (error$1 instanceof Error) {
                logger.error(error$1.message);
            }
        }
    });
}

/**
 * Wrapper component to lazy load the MFE. And also add some other validations
 * @param mfeName - MFE name
 * @param componentName - Component name
 * @returns Lazy loaded component
 */
export const loadMicroFrontendComponent = (
    mfeName: Microfrontend,
    componentName: any,
) => {
    logger.info(`Loading MFE ${mfeName} ${componentName}`);
    const importComp = () => getMicroFrontendComponent(mfeName, componentName);
    // Lazy load the component
    const Component = React.lazy(importComp);

    // Load the component again to get access to the promise
    // which resolves to the component
    const importCompPromise = importComp();

    logger.info(
        `Component ${componentName} of MFE ${mfeName} loaded successfully`,
    );

    return (props: any) => {
        const { metricsService } = useAppContext();
        useEffect(() => {
            // eslint-disable-next-line promise/catch-or-return
            importCompPromise.then(
                (Comp: any) =>
                    Comp?.default?.propTypes &&
                    checkPropTypes(Comp?.default?.propTypes, props, mfeName),
            );
        }, [props]);
        if (!initializedMicroFrontends[mfeName]) {
            initializedMicroFrontends[mfeName] = true;
            getMfeResourceUrls(mfeName)
                .then(mfeInfo => {
                    metricsService.collectEvent(
                        new UserAction(userActionTypeEnum.MFE_LOADED),
                        {
                            mfe_name: mfeName,
                            mfe_release_version:
                                mfeInfo.mfeReleaseVersion ?? 'NA',
                            mfe_build_number: mfeInfo.mfeBuildNumber ?? 'NA',
                        },
                    );
                    return null;
                })
                .catch(e => logger.error(e));
        }
        return <Component {...props} />;
    };
};

/**
 * Wrapper component to lazy load the MFE. And also add some other validations
 * @param mfeName - MFE name
 * @param functionName - Function name
 * @returns Lazy loaded component
 */
export const loadMicroFrontendFunction = async (
    mfeName: Microfrontend,
    functionName: any,
) => {
    logger.info(`Loading MFE ${mfeName} ${functionName}`);
    const importComp = getMicroFrontendComponent(mfeName, functionName);

    logger.info(
        `Function ${functionName} of MFE ${mfeName} loaded successfully`,
    );

    return (await importComp).default;
};
